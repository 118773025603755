import { createApp } from 'vue'
import App from './App.vue'

import SolanaWallets from "solana-wallets-vue";
import "solana-wallets-vue/styles.css";
import { initWallet } from "solana-wallets-vue";
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  BackpackWalletAdapter,
  BitKeepWalletAdapter,
  BraveWalletAdapter,
  CloverWalletAdapter,
  Coin98WalletAdapter,
  CoinbaseWalletAdapter,
  ExodusWalletAdapter,
  GlowWalletAdapter,
  LedgerWalletAdapter,
  MathWalletAdapter,
  SolongWalletAdapter,
  SpotWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
  

const walletOptions = {
    wallets: [
      new PhantomWalletAdapter('https://solana-mainnet.g.alchemy.com/v2/POMqvobcGOER_NECApg9ggZPaJ9vWe3G'),
      new ExodusWalletAdapter(),
      new BraveWalletAdapter(),
      new CoinbaseWalletAdapter(),
      new BackpackWalletAdapter(),
      new SolflareWalletAdapter(),
      new TorusWalletAdapter(),
      new CloverWalletAdapter(),
      new Coin98WalletAdapter(),
      new LedgerWalletAdapter(),
      new SolongWalletAdapter(),
      new MathWalletAdapter(),
      new GlowWalletAdapter(),
      new SpotWalletAdapter(),
      new BitKeepWalletAdapter()
    ],
    autoConnect: false,
  };

initWallet(walletOptions)

createApp(App).use(SolanaWallets, walletOptions).mount("#app");

